/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
import { BEFORE_ITEMS_TYPE } from 'Component/Router/Router.config';

import Heap from '../route/Heap';

export const addHeap = (originalMember) => [
    ...originalMember,
    {
        component: <Heap />,
        position: 103
    }
];

export default {
    'Component/Router/Component': {
        'member-property': {
            [BEFORE_ITEMS_TYPE]: addHeap
        }
    }
};
