/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import Overlay from 'Component/Overlay';
import { NO_MAP_RESTRICTIONS, VERIFIED_CUSTOMERS, WHOLESALE } from 'Component/ProductActions/ProductActions.config';
import { CONFIGURABLE } from 'Component/ProductSwatchPopup/ProductSwatchPopup.config';
import { RANGE_TYPE, VALUE_TYPE } from 'Component/SearchSpringListFacet/SearchSpringListFacet.config';
import { DeviceType } from 'Type/Device';
import history from 'Util/History';

import {
    ACCESSORIES_ATTRIBUTE_ID,
    ALL_ACCESSORIES,
    BRAND_FIELD,
    FIVE_BRANDS,
    FLOORING_ATTRIBUTE_ID,
    FOUR_PRODUCTS_IN_MOBILE_SEARCH,
    PRICE_FIELD,
    RUG_ATTRIBUTE_ID,
    SEARCHSPRING_SEARCH_OVERLAY
} from './SearchSpringOverlay.config';

import './SearchSpringOverlay.style';

/** @namespace ZnetPwa/Component/SearchSpringOverlay/Component/SearchSpringOverlayComponent */
export class SearchSpringOverlayComponent extends PureComponent {
    static propTypes = {
        customerGroupName: PropTypes.string.isRequired,
        search: PropTypes.string.isRequired,
        isCorrectedQueryPresent: PropTypes.bool.isRequired,
        alternativeQueries: PropTypes.arrayOf(
            PropTypes.string.isRequired
        ).isRequired,
        isLoading: PropTypes.bool.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        brandFacets: PropTypes.array.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        priceFacets: PropTypes.array.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        searchState: PropTypes.object.isRequired,
        device: DeviceType.isRequired,
        didYouMean: PropTypes.string.isRequired,
        totalResults: PropTypes.number.isRequired,
        categorySuggestions: PropTypes.arrayOf(PropTypes.string).isRequired,
        onSuggestionClick: PropTypes.func.isRequired,
        toggleOverlayOnClick: PropTypes.func.isRequired,
        onFilterOptionClick: PropTypes.func.isRequired,
        createCategorySearchLink: PropTypes.func.isRequired,
        productSearch: PropTypes.arrayOf(
            PropTypes.objectOf(
                PropTypes.shape({
                    image: PropTypes.string,
                    brand: PropTypes.string,
                    color: PropTypes.string,
                    width: PropTypes.string,
                    sku: PropTypes.string,
                    url: PropTypes.string,
                    request_quote_enabled: PropTypes.string,
                    final_price: PropTypes.string,
                    special_price: PropTypes.string,
                    unit_price: PropTypes.string,
                    attribute_set_id: PropTypes.number,
                    name: PropTypes.string,
                    collection_name: PropTypes.string,
                    show_price: PropTypes.bool,
                    max_price: PropTypes.string,
                    coverage_area_type: PropTypes.string,
                    configurableMinPrice: PropTypes.number,
                    configurableMaxPrice: PropTypes.number,
                    type: PropTypes.number,
                    regular_price: PropTypes.string
                })
            )
        ).isRequired
    };

    productTypeMap = {
        [FLOORING_ATTRIBUTE_ID]: {
            render: (product) => this.renderFlooring(product)
        },
        [RUG_ATTRIBUTE_ID]: {
            render: (product) => this.renderRugs(product)
        },
        [ACCESSORIES_ATTRIBUTE_ID]: {
            render: (product) => this.renderAccessories(product)
        }
    };

    renderCategorySuggestions() {
        const { categorySuggestions, createCategorySearchLink, toggleOverlayOnClick } = this.props;
        const categorySuggestionsWithAccessories = categorySuggestions;

        if (!categorySuggestionsWithAccessories.includes(ALL_ACCESSORIES)) {
            categorySuggestionsWithAccessories.push(ALL_ACCESSORIES);
        }

        return (
            <div block="SearchSpringOverlay" elem="Section">
                <div block="SearchSpringOverlay" elem="Section-Content">
                <h5 block="SearchSpringOverlay" elem="Section-Title">{ __('Categories') }</h5>
                    { categorySuggestionsWithAccessories.map((suggestion, idx) => {
                        if (idx === categorySuggestionsWithAccessories.length - 1) {
                            return (
                                <Link
                                  block="AccessoryLink SearchSpringOverlay"
                                  elem="Section-Link"
                                  key={ suggestion }
                                  to={ createCategorySearchLink(suggestion) }
                                  onClick={ toggleOverlayOnClick }
                                >
                                { suggestion }
                                </Link>
                            );
                        }

                        return (
                            <Link
                              block="SearchSpringOverlay"
                              elem="Section-Link"
                              key={ suggestion }
                              to={ createCategorySearchLink(suggestion) }
                              onClick={ toggleOverlayOnClick }
                            >
                            { suggestion }
                            </Link>
                        );
                    }) }
                </div>
            </div>
        );
    }

    renderProductTypePriceBadge(requestQuote) {
        const {
            customerGroupName
        } = this.props;
        const label = (requestQuote === 'Yes' && customerGroupName !== VERIFIED_CUSTOMERS)
            ? __('Price In Quote')
            : __('Price In Cart');

        return (
            <div block="ProductCard" elem="PriceBadgeWrapper">
                <p
                  mix={ {
                      block: 'ProductCard',
                      elem: 'PriceBadge'
                  } }
                >
                    { label }
                </p>
            </div>
        );
    }

    renderProductPrice(product) {
        const {
            customerGroupName
        } = this.props;
        const {
            attribute_set_id,
            coverage_area_type,
            request_quote_enabled,
            show_price,
            final_price,
            regular_price,
            special_price,
            unit_price,
            type,
            configurableMinPrice,
            configurableMaxPrice
        } = product;
        const isNoMapRestrictions = customerGroupName === NO_MAP_RESTRICTIONS || customerGroupName === WHOLESALE;
        const isRequestQuote = request_quote_enabled === 'Yes';
        const isShowPrice = show_price && show_price === 'Yes';

        const displayedUnitPrice = (isRequestQuote && !isShowPrice) ? '' : unit_price || '';
        const isSpecialPrice = !!special_price && displayedUnitPrice;
        const msrp = isSpecialPrice
            ? `Msrp: $${displayedUnitPrice}`
            : `$${displayedUnitPrice} `;
        const msrpToDisplay = (isRequestQuote && !isShowPrice) ? '' : msrp;
        const isLineThrough = !!special_price;
        const isNormalPrice = !special_price;
        const isHidden = show_price && show_price === 'No' && !isNoMapRestrictions;

        if (attribute_set_id === ACCESSORIES_ATTRIBUTE_ID) {
            return (
                <div block="ProductCard" elem="PriceWrapper" mods={ { isLineThrough } }>
                    { `$${final_price}` }
                    <span block="ProductCard" elem="PriceWrapper-Units">
                        { `/${coverage_area_type}` }
                    </span>
                    <div
                      block="ProductCard"
                      elem="PriceWrapper-Price"
                      mods={ {
                          isAccessory: true,
                          isLineThrough,
                          isNormalPrice,
                          isHidden: ((show_price && show_price === 'No') || isNormalPrice)
                      } }
                    >
                        { msrpToDisplay }
                    </div>
                </div>
            );
        }

        if (attribute_set_id === RUG_ATTRIBUTE_ID) {
            if (type === CONFIGURABLE) {
                const price = `$${ configurableMinPrice }-$${ configurableMaxPrice }`;

                return (
                    <div block="ProductCard" elem="PriceWrapper">
                        <div
                          block="ProductCard"
                          elem="PriceWrapper-Price"
                          mods={ {
                              isHidden
                          } }
                        >
                            { price }
                        </div>
                        { (isRequestQuote) && this.renderProductTypePriceBadge() }
                    </div>
                );
            }

            const rugMsrp = special_price
                ? `Msrp: $${Number(regular_price).toFixed(2)}`
                : `$${Number(regular_price).toFixed(2)}`;

            return (
                <div block="ProductCard" elem="PriceWrapper" mods={ { isLineThrough } }>
                    <div
                      block="ProductCard"
                      elem="PriceWrapper-RugPrice"
                      mods={ { isLineThrough, isNormalPrice, isHidden } }
                    >
                        { rugMsrp }
                    </div>
                    { (!!special_price || isRequestQuote) && this.renderProductTypePriceBadge() }
                </div>
            );
        }

        return (
                <div block="ProductCard" elem="PriceWrapper" mods={ { isLineThrough } }>
                    <div
                      block="ProductCard"
                      elem="PriceWrapper-Price"
                      mods={ {
                          isLineThrough,
                          isNormalPrice,
                          isHidden: (show_price && show_price === 'No' && !isNoMapRestrictions)
                      } }
                    >
                        { msrpToDisplay }
                        { !isSpecialPrice && (!isRequestQuote || isShowPrice) && (
                            <span block="ProductCard" elem="PriceWrapper-Units">
                                / sq. ft.
                            </span>
                        ) }
                    </div>
                    <div
                      block="ProductCard"
                      elem="PriceWrapper-BoxPrice"
                      mods={ {
                          isBoxPriceHidden: ((!!isSpecialPrice
                          || (isRequestQuote && !isShowPrice)
                          || (show_price && show_price === 'No'))
                          && !isNoMapRestrictions)
                      } }
                    >
                        { `$${ final_price } / box` }
                    </div>
                    { (!!isSpecialPrice || isRequestQuote)
                        && this.renderProductTypePriceBadge(request_quote_enabled) }
                </div>
        );
    }

    renderFlooring(product) {
        const {
            toggleOverlayOnClick
        } = this.props;

        return (
            <div
              key={ product.sku }
              block="SearchSpringOverlay"
              elem="ProductResult"
            >
                <Link
                  block="SearchSpringOverlay"
                  elem="Link"
                  to={ product.url }
                  onClick={ toggleOverlayOnClick }
                >
                    <div
                      block="SearchSpringOverlay"
                      elem="ImageContentWrapper"
                    >
                        <div
                          block="SearchSpringOverlay"
                          elem="ProductImageWrapper"
                        >
                            <Image
                              block="SearchSpringOverlay"
                              elem="Image"
                              ratio="square"
                              style={ { objectFit: 'cover' } }
                              src={ `${product.image}` }
                              alt={ __('Product %s thumbnail.', product.name) }
                            />
                        </div>
                        <div block="SearchSpringOverlay" elem="Content">
                            <h4>
                                { product.color }
                            </h4>
                            <h4
                              block="SearchSpringOverlay"
                              elem="ProductBrandAndWidth"
                            >
                                { `${ product.brand } | ${ product.width }` }
                            </h4>
                            <h4
                              block="SearchSpringOverlay"
                              elem="CollectionName"
                            >
                                { product.collection_name }
                            </h4>
                            { this.renderProductPrice(product) }
                        </div>
                    </div>
                </Link>
            </div>
        );
    }

    renderRugs(product) {
        return (
            <div
              key={ product.sku }
              block="SearchSpringOverlay"
              elem="ProductResult"
            >
                <Link
                  block="SearchSpringOverlay"
                  elem="Link"
                  to={ product.url }
                >
                    <div
                      block="SearchSpringOverlay"
                      elem="ImageContentWrapper"
                    >
                        <div
                          block="SearchSpringOverlay"
                          elem="ProductImageWrapper"
                        >
                            <Image
                              block="SearchSpringOverlay"
                              elem="Image"
                              ratio="square"
                              style={ { objectFit: 'cover' } }
                              src={ `${product.image}` }
                              alt={ __('Product %s thumbnail.', product.name) }
                            />
                        </div>
                        <div block="SearchSpringOverlay" elem="Content">
                            <h4>
                                { product.color }
                            </h4>
                            <h4
                              block="SearchSpringOverlay"
                              elem="CollectionName"
                            >
                                { product.collection_name }
                            </h4>
                            <h4
                              block="SearchSpringOverlay"
                              elem="ProductBrandAndWidth"
                            >
                                { `${ product.brand }` }
                            </h4>
                            { this.renderProductPrice(product) }
                        </div>
                    </div>
                </Link>
            </div>
        );
    }

    renderAccessories(product) {
        const {
            toggleOverlayOnClick
        } = this.props;

        return (
            <div
              key={ product.sku }
              block="SearchSpringOverlay"
              elem="ProductResult"
            >
                <Link
                  block="SearchSpringOverlay"
                  elem="Link"
                  to={ product.url }
                  onClick={ toggleOverlayOnClick }
                >
                    <div
                      block="SearchSpringOverlay"
                      elem="ImageContentWrapper"
                    >
                        <div
                          block="SearchSpringOverlay"
                          elem="ProductImageWrapper"
                        >
                            <Image
                              block="SearchSpringOverlay"
                              elem="Image"
                              ratio="square"
                              style={ { objectFit: 'cover' } }
                              src={ `${product.image}` }
                              alt={ __('Product %s thumbnail.', product.name) }
                            />
                        </div>
                        <div block="SearchSpringOverlay" elem="Content">
                            <h4
                              block="SearchSpringOverlay"
                              elem="CollectionName"
                            >
                                { product.name }
                            </h4>
                            { this.renderProductPrice(product) }
                        </div>
                    </div>
                </Link>
            </div>
        );
    }

    /**
     * Render products for search overlay.
     *
     * @return {JSX}
     */
    renderProductsWrapper() {
        const {
            productSearch,
            device: {
                isMobile
            },
            search,
            didYouMean,
            onSuggestionClick
        } = this.props;
        const adjustedProductSearch = isMobile ? productSearch.slice(0, FOUR_PRODUCTS_IN_MOBILE_SEARCH) : productSearch;

        if (!adjustedProductSearch.length) {
            if (didYouMean !== '') {
                return (
                    <div
                      block="SearchSpringOverlay"
                      elem="ProductResults"
                      mods={ { notFound: true } }
                    >
                        <h4>
                            { __('No results for search ') }
                            <span
                              block="SearchSpringOverlay"
                              elem="ProductResults"
                              mods={ { highlight: true } }
                            >
                                { ` "${ search }"` }
                                .
                            </span>
                            { __(' Did you mean ') }
                            <Link
                              block="SearchSpringOverlay"
                              elem="ProductResults"
                              mods={ { highlight: true } }
                              to={ `/search/${ didYouMean }` }
                              // eslint-disable-next-line react/jsx-no-bind
                              onClick={ () => onSuggestionClick(didYouMean) }
                            >
                                { ` ${ didYouMean }` }
                            </Link>
                            ?
                        </h4>
                        <h4
                          block="SearchSpringOverlay"
                          elem="NotFoundAccessorySuggestion"
                        >
                            { __('If you are searching for accessories click the category "All Accessories"') }
                        </h4>
                    </div>
                );
            }

            return (
                <div
                  block="SearchSpringOverlay"
                  elem="ProductResults"
                  mods={ { notFound: true } }
                >
                    <h4>
                        { __('No results for search ') }
                        <span>{ `"${ search }"` }</span>
                    </h4>
                    <h4
                      block="SearchSpringOverlay"
                      elem="NotFoundAccessorySuggestion"
                    >
                        { __('If you are searching for accessories click the category "All Accessories"') }
                    </h4>
                </div>
            );
        }

        return adjustedProductSearch.map((product) => this.productTypeMap[product.attribute_set_id]?.render(product));
    }

    /**
     * Render link to search for all products with current query.
     *
     * @return {JSX}
     */
    renderAllResults() {
        const {
            totalResults,
            search,
            toggleOverlayOnClick
        } = this.props;

        if (!totalResults) {
            return null;
        }

        return (
            <div
              block="SearchSpringOverlay"
              elem="AllResults"
            >
                <Link
                  block="SearchSpringOverlay"
                  elem="Link"
                  to={ `/search/${ search }` }
                  onClick={ toggleOverlayOnClick }
                >
                    { __(`Show all ${ totalResults } results`) }
                </Link>
            </div>
        );
    }

    /**
     * Render autocomplete preview filter options with brands.
     *
     * @return {JSX|null}
     */
    renderBrandSuggestions() {
        const { brandFacets, searchState, onFilterOptionClick } = this.props;

        if (brandFacets.length) {
            return (
                <div block="SearchSpringOverlay" elem="Section">
                    <div block="SearchSpringOverlay" elem="Section-Content">
                        <h5 block="SearchSpringOverlay" elem="Section-Title">{ __('Brand') }</h5>
                        { brandFacets[0].values.slice(0, FIVE_BRANDS).map(({
                            value,
                            label,
                            active
                        }, i) => {
                            const filterArray = [{
                                type: VALUE_TYPE,
                                id: `${ BRAND_FIELD }_${i}`,
                                field: BRAND_FIELD,
                                label,
                                value,
                                categoryLabel: 'Brand'
                            }];
                            const selected = active;

                            return (
                            <div
                              block="SearchSpringOverlay"
                              elem="Section-Link"
                              // eslint-disable-next-line react/jsx-no-bind
                              onClick={ () => {
                                  onFilterOptionClick(`${ BRAND_FIELD }_${i}`,
                                      selected, filterArray, searchState, history);
                              } }
                              // eslint-disable-next-line react/jsx-no-bind
                              onKeyDown={ () => {
                                  onFilterOptionClick(`${ BRAND_FIELD }_${i}`,
                                      selected, filterArray, searchState, history);
                              } }
                              role="button"
                              tabIndex={ 0 }
                            >
                                { label }
                            </div>
                            );
                        }) }
                    </div>
                </div>
            );
        }

        return null;
    }

    /**
     * Render autocomplete preview filter options with prices.
     *
     * @return {JSX|null}
     */
    renderPriceSuggestions() {
        const { priceFacets, searchState, onFilterOptionClick } = this.props;

        if (priceFacets.length) {
            return (
                <div block="SearchSpringOverlay" elem="Section">
                <div block="SearchSpringOverlay" elem="Section-Content">
                <h5 block="SearchSpringOverlay" elem="Section-Title">{ __('Price') }</h5>
                    { priceFacets[0].values.map(({
                        low,
                        high,
                        label,
                        active
                    }, i) => {
                        const filterArray = [{
                            type: RANGE_TYPE,
                            id: `${ PRICE_FIELD }_${i}`,
                            field: PRICE_FIELD,
                            label,
                            low,
                            high,
                            categoryLabel: 'Price'
                        }];
                        const selected = active;

                        return (
                        <div
                          block="SearchSpringOverlay"
                          elem="Section-Link"
                          // eslint-disable-next-line react/jsx-no-bind
                          onClick={ () => {
                              onFilterOptionClick(`${ PRICE_FIELD }_${i}`,
                                  selected, filterArray, searchState, history);
                          } }
                          // eslint-disable-next-line react/jsx-no-bind
                          onKeyDown={ () => {
                              onFilterOptionClick(`${ PRICE_FIELD }_${i}`,
                                  selected, filterArray, searchState, history);
                          } }
                          role="button"
                          tabIndex={ 0 }
                        >
                            { label }
                        </div>
                        );
                    }) }
                </div>
                </div>
            );
        }

        return null;
    }

    renderSuggestedLink(alternativeQuery, idx) {
        const { onSuggestionClick, isCorrectedQueryPresent } = this.props;

        return (
            <button
              block="SearchSpringOverlay"
              elem="ResultsColumnSuggestedQuery"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => onSuggestionClick(alternativeQuery) }
              mods={ { isBold: isCorrectedQueryPresent && idx === 0 } }
            >
              { ` ${ alternativeQuery }` }
            </button>
        );
    }

    renderSuggestedQuery() {
        const { alternativeQueries } = this.props;

        if (alternativeQueries.length === 0) {
            return null;
        }

        return (
            <div
              block="SearchSpringOverlay"
              elem="ResultsColumn"
              mods={ { Products: true } }
            >
                <h2
                  block="SearchSpringOverlay"
                  elem="ResultsColumnHeading"
                >
                    { __('Other Search Terms') }
                </h2>
                <div
                  block="SearchSpringOverlay"
                  elem="AlternativeQueries"
                >
                    { alternativeQueries.map((query, idx) => this.renderSuggestedLink(query, idx)) }
                </div>
            </div>
        );
    }

    render() {
        const { isLoading } = this.props;

        return (
            <Overlay
              id={ SEARCHSPRING_SEARCH_OVERLAY }
              mix={ { block: 'SearchSpringOverlay' } }
            >
                <article
                  block="SearchSpringOverlay"
                  elem="Results"
                  aria-label="Search results"
                >
                    <Loader isLoading={ isLoading } />
                    <div
                      block="SearchSpringOverlay"
                      elem="ResultsColumn"
                      mods={ { Products: true } }
                    >
                        { this.renderSuggestedQuery() }
                        <div
                          block="SearchSpringOverlay"
                          elem="ResultsColumnWrapper"
                        >
                            <h2
                              block="SearchSpringOverlay"
                              elem="ResultsColumnHeading"
                            >
                                { __('Results') }
                            </h2>
                            { this.renderAllResults() }
                        </div>
                        { this.renderProductsWrapper() }
                    </div>
                    <div
                      block="SearchSpringOverlay"
                      elem="ResultsColumn"
                    >
                        { this.renderCategorySuggestions() }
                    </div>
                </article>
            </Overlay>
        );
    }
}

export default SearchSpringOverlayComponent;
