/* eslint-disable import/prefer-default-export */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/braintree-graphql
 * @link https://github.com/scandipwa/braintree-graphql
 */

export const BRAINTREE_CONTAINER_ID = 'BRAINTREE_CONTAINER_ID';
export const BRAINTREE = 'braintree';
export const BRAINTREE_PAYPAL = 'braintree_paypal';
export const APPLE_PAY = 'braintree_applepay';
export const LOCAL_PAYMENT = 'braintree_local_payment';
export const VENMO = 'braintree_venmo';
export const PRIORITY_ID = {
    [APPLE_PAY]: 'applePay',
    [BRAINTREE]: 'card'
};
export const APPLE_PAY_VERSION = 3;
export const BRAINTREE_METHODS = [BRAINTREE, APPLE_PAY, LOCAL_PAYMENT, VENMO, BRAINTREE_PAYPAL];
