import { datadogRum } from '@datadog/browser-rum';

import { HIDE_NOTIFICATION, SHOW_NOTIFICATION } from 'SourceStore/Notification/Notification.action';
import {
    getInitialState
} from 'SourceStore/Notification/Notification.reducer';
import BrowserDatabase from 'SourceUtil/BrowserDatabase';

/** @namespace ZnetPwa/Store/Notification/Reducer/NotificationReducer */
export const NotificationReducer = (
    state = getInitialState(),
    action
) => {
    const notifications = { ...state.notifications };

    switch (action.type) {
    case SHOW_NOTIFICATION:
        const { msgType, msgText, msgDebug } = action;
        const { storeConfig } = BrowserDatabase.getItem('config') || {};
        const {
            datadog_enabled = false
        } = storeConfig;

        notifications[Date.now()] = { msgType, msgText, msgDebug };

        if (msgType === 'error' && datadog_enabled) {
            const message = msgText || msgDebug;
            const errorMessage = new Error(message);
            datadogRum.addError(errorMessage);
        }

        return {
            ...state,
            notifications
        };

    case HIDE_NOTIFICATION:
        // eslint-disable-next-line no-unused-vars
        const { [action.id]: id, ...shownNotifications } = notifications;

        return {
            ...state,
            notifications: shownNotifications
        };

    default:
        return state;
    }
};
export default NotificationReducer;
