import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Scripts from './Scripts';

/** @namespace HeapSpwa/Route/Heap/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isHeapEnabled: state.ConfigReducer.heap_enabled,
    heapAppId: state.ConfigReducer.heap_app_id,
    isLoading: state.ConfigReducer.isLoading
});

/** @namespace HeapSpwa/Route/Heap/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace HeapSpwa/Route/Heap/Container/HeapContainer */
export class HeapContainer extends PureComponent {
    static propTypes = {
        isHeapEnabled: PropTypes.bool.isRequired,
        heapAppId: PropTypes.string.isRequired,
        isLoading: PropTypes.bool.isRequired
    };

    state = {
        isHeapInjected: false
    };

    componentDidMount() {
        this.initHeap();
    }

    componentDidUpdate(prevProps) {
        const {
            isHeapEnabled,
            isLoading
        } = this.props;
        const {
            isHeapEnabled: prevIsHeapEnabled
        } = prevProps;
        const {
            isHeapInjected
        } = this.state;

        if (!isLoading && !isHeapEnabled !== prevIsHeapEnabled && isHeapEnabled && !isHeapInjected) {
            this.initHeap();
        }
    }

    initHeap() {
        const {
            isHeapEnabled,
            heapAppId
        } = this.props;
        const {
            isHeapInjected
        } = this.state;

        if (isHeapEnabled && heapAppId && !isHeapInjected) {
            this.setState({ isHeapInjected: true }, () => {
                const script = document.createElement('script');
                script.id = 'HEAP_SCRIPT';
                script.innerHTML = Scripts.getScript({ heapAppId });
                document.head.insertBefore(script, document.head.childNodes[0]);
            });
        }
    }

    render() {
        return null;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeapContainer));
