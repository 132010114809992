import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    NewVersionPopupContainer as SourceNewVersionPopupContainer
} from 'SourceComponent/NewVersionPopup/NewVersionPopup.container';
import { isCrawler, isSSR } from 'Util/Browser';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace ZnetPwa/Component/NewVersionPopup/Container/NewVersionPopupContainer */
export class NewVersionPopupContainer extends SourceNewVersionPopupContainer {
    componentDidMount() {
        const { showPopup, goToPreviousHeaderState, device } = this.props;

        if (isCrawler() || isSSR()) {
            // disable popup for crawlers so page content is not blocked and page is scrollable
            return;
        }

        const { serviceWorker: { controller } = {} } = navigator;

        if (!controller) {
            return;
        }

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                showPopup({
                    title: __('New version available!')
                });

                if (device.isMobile) {
                    goToPreviousHeaderState();
                }
            });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewVersionPopupContainer);
