import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CART_OVERLAY } from 'Component/Header/Header.config';
import {
    CartOverlayContainer as SourceCartOverlay,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/CartOverlay/CartOverlay.container';
import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { toggleOverlayByKey } from 'Store/Overlay/Overlay.action';

/** @namespace ZnetPwa/Component/CartOverlay/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
});

/** @namespace ZnetPwa/Component/CartOverlay/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    toggleOverlayByKey: (CART_OVERLAY) => dispatch(toggleOverlayByKey(CART_OVERLAY)),
    goToPreviousNavigationState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE))
});

/** @namespace ZnetPwa/Component/CartOverlay/Container/CartOverlayContainer */
export class CartOverlayContainer extends SourceCartOverlay {
    static propTypes = {
        ...this.propTypes,
        goToPreviousNavigationState: PropTypes.func.isRequired,
        toggleOverlayByKey: PropTypes.func.isRequired
    };

    containerFunctions = {
        ...this.containerFunctions,
        hidePopUp: this.hidePopUp.bind(this)
    };

    hidePopUp() {
        const { toggleOverlayByKey, goToPreviousNavigationState } = this.props;

        goToPreviousNavigationState();
        toggleOverlayByKey(CART_OVERLAY);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartOverlayContainer);
