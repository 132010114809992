/* eslint-disable max-lines */
import { CONFIGURABLE } from '@scandipwa/scandipwa/src/util/Product';
import PropTypes from 'prop-types';
import {
    BsCheck2, BsPlusSquare, BsTrash, BsX
} from 'react-icons/bs';

import CartItemPrice from 'Component/CartItemPrice';
import { MAX_ACCESSORIES_COUNT, VIRTUAL } from 'Component/CartPopup/CartPopup.config';
import Field from 'Component/Field';
import ImageWebp from 'Component/ImageWebp';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import Popup from 'Component/Popup';
import { RUGS } from 'Component/ProductActions/ProductActions.config';
import ProductLinks from 'Component/ProductLinks';
import { ACCESSORIES_ATTRIBUTE_SET_NAME, PREMIUM_CUSTOMER } from 'Route/ProductPage/ProductPage.config';
import {
    CartItem as SourceCartItem
} from 'SourceComponent/CartItem/CartItem.component';
import { CROSS_SELL } from 'Store/LinkedProducts/LinkedProducts.reducer';
import { ProductType } from 'Type/ProductList';

import { ACCESSORIES_ATTRIBUTE, ACCESSORIES_POPUP, FLOORING_ATTRIBUTE } from './CartItem.config';

import './CartItem.override.style';

/** @namespace ZnetPwa/Component/CartItem/Component/CartItemComponent */
export class CartItemComponent extends SourceCartItem {
    static propTypes = {
        ...this.propTypes,
        addProductToWishlist: PropTypes.func.isRequired,
        isCart: PropTypes.bool,
        weight: PropTypes.number.isRequired,
        onConfirmClick: PropTypes.func.isRequired,
        onCartItemAddToCart: PropTypes.func.isRequired,
        onConfirmQtyChange: PropTypes.func.isRequired,
        toggleCustomQtyInput: PropTypes.func.isRequired,
        isCartPopup: PropTypes.bool.isRequired,
        isCustomQtyInputVisible: PropTypes.bool.isRequired,
        isConfirmQtyVisible: PropTypes.bool.isRequired,
        isAddingAccessory: PropTypes.bool.isRequired,
        isVirtual: PropTypes.bool.isRequired,
        qtyInputRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ]).isRequired,
        cartItemQuantity: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
        ]).isRequired,
        hideConfirmQty: PropTypes.func.isRequired,
        product: ProductType.isRequired,
        onCartItemAddToCartClick: PropTypes.func.isRequired,
        activeQtyInput: PropTypes.number.isRequired,
        id: PropTypes.number.isRequired
    };

    static defaultProps = {
        ...this.defaultProps,
        isCart: false
    };

    renderProductPrice() {
        const {
            isLikeTable,
            currency_code,
            item: {
                row_total,
                row_total_incl_tax
            },
            isCart,
            isCheckout
        } = this.props;

        if (isCart) {
            return (
                <CartItemPrice
                  row_total={ row_total }
                  row_total_incl_tax={ row_total_incl_tax }
                  currency_code={ currency_code }
                  mix={ {
                      block: 'CartItem',
                      elem: 'Price',
                      mods: { isLikeTable }
                  } }
                />
            );
        }

        if (isCheckout) {
            return (
                <div
                  block="CartItem"
                  elem="TotalWrapper"
                >
                    <CartItemPrice
                      row_total={ row_total }
                      row_total_incl_tax={ row_total_incl_tax }
                      currency_code={ currency_code }
                      mix={ {
                          block: 'CartItem',
                          elem: 'Price',
                          mods: { isLikeTable }
                      } }
                    />
                </div>
            );
        }

        return (
            <div
              block="CartItem"
              elem="TotalWrapper"
            >
                <CartItemPrice
                  row_total={ row_total }
                  row_total_incl_tax={ row_total_incl_tax }
                  currency_code={ currency_code }
                  mix={ {
                      block: 'CartItem',
                      elem: 'Price',
                      mods: { isLikeTable }
                  } }
                />
            </div>
        );
    }

    renderProductSku() {
        const { item: { sku } } = this.props;

        return (
            <span
              block="CartItem"
              elem="Sku"
            >
                { sku }
            </span>
        );
    }

    renderProductMapPrice() {
        const {
            item: {
                product: {
                    sku,
                    dynamicAttributes: {
                        price,
                        unit_price,
                        special_price,
                        coverage_area_type
                    } = {}
                } = {}
            } = {}
        } = this.props;

        if ((!special_price && unit_price?.substring(1) <= 0) || sku.endsWith('-sample')) {
            return null;
        }

        return (
            <div
              block="CartItem"
              elem="UnitPriceWrapper"
            >
                <dt
                  block="CartItem"
                  elem="UnitPriceTitle"
                >
                    { __('Unit price: ') }
                </dt>
                &nbsp;
                <dd
                  block="CartItem"
                  elem="OneUnitPrice"
                >
                    { `${ price } ${ coverage_area_type }` }
                </dd>
            </div>
        );
    }

    renderCollectionColor() {
        const {
            item: {
                product: {
                    name,
                    dynamicAttributes: {
                        collection_color
                    }
                }
            }
        } = this.props;

        if (!collection_color && name) {
            return (
                <h4
                  block="CartItem"
                  elem="Color"
                >
                    { name }
                </h4>
            );
        }

        return (
            <h4
              block="CartItem"
              elem="Color"
            >
                { collection_color }
            </h4>
        );
    }

    renderBrandWidth() {
        const {
            item: {
                product: {
                    dynamicAttributes: {
                        brand,
                        plank_width = '',
                        attribute_set_name
                    }
                }
            }
        } = this.props;

        if (attribute_set_name !== FLOORING_ATTRIBUTE) {
            return null;
        }

        const plankWidth = plank_width ? `${ plank_width } in. ` : '';
        const productNameString = plankWidth ? `${ brand } | ${ plankWidth }` : `${ brand }`;

        return (
            <h4
              block="CartItem"
              elem="BrandWidth"
            >
                { productNameString }
            </h4>
        );
    }

    renderCollectionName() {
        const {
            item: {
                product: {
                    dynamicAttributes: {
                        collection_name,
                        attribute_set_name
                    }
                }
            }
        } = this.props;

        if (attribute_set_name !== FLOORING_ATTRIBUTE) {
            return null;
        }

        return (
            <h4
              block="CartItem"
              elem="CollectionName"
            >
                { collection_name }
            </h4>
        );
    }

    renderWrapper() {
        return (
            <span block="CartItem" elem="Link">
                { this.renderWrapperContent() }
            </span>
        );
    }

    renderWrapperContent() {
        const { isCart, device } = this.props;

        if (isCart) {
            return (
                <figure block="CartItem" elem="Wrapper" mods={ { isCart } }>
                    { this.renderImage() }
                    { this.renderCartContent() }
                </figure>
            );
        }

        if (device.isMobile) {
            return (
                <figure block="CartItem" elem="Wrapper">
                    { this.renderImage() }
                    { this.renderContent() }
                </figure>
            );
        }

        return (
            <figure block="CartItem" elem="Wrapper">
                { this.renderImage() }
                { this.renderContent() }
            </figure>
        );
    }

    renderProperties() {
        const {
            item: {
                product: {
                    dynamicAttributes: {
                        attribute_set_name
                    }
                }
            },
            isCart
        } = this.props;

        const isAccessory = attribute_set_name === ACCESSORIES_ATTRIBUTE;

        return (
            <div
              block="CartItem"
              elem="Description"
            >
                <div>
                    <div block="CartItem" elem="HeadingWrapper" mods={ { isAccessory, isCart } }>
                        { this.renderCollectionColor() }
                        { this.renderProductSku() }
                    </div>
                    { this.renderBrandWidth() }
                    { this.renderCollectionName() }
                </div>
                { this.renderProductConfigurations() }
            </div>
        );
    }

    renderImageElement() {
        const {
            item: {
                product: {
                    sku,
                    name
                }
            },
            thumbnail,
            isProductInStock,
            isCart,
            linkTo,
            closePopup
        } = this.props;
        const alt = `${ sku } - ${ name }`;

        const isNotAvailable = !isProductInStock;

        if (linkTo) {
            return (
                <Link to={ linkTo } block="CartItem" elem="Link" onClick={ closePopup }>
                    <ImageWebp
                      src={ thumbnail }
                      mix={ {
                          block: 'CartItem',
                          elem: 'Picture',
                          mods: {
                              isNotAvailable,
                              isCart
                          }
                      } }
                      ratio="custom"
                      alt={ alt }
                    />
                </Link>
            );
        }

        return (
            <ImageWebp
              src={ thumbnail }
              mix={ {
                  block: 'CartItem',
                  elem: 'Picture',
                  mods: {
                      isNotAvailable,
                      isCart
                  }
              } }
              ratio="custom"
              alt={ alt }
            />
        );
    }

    renderPackageProductPrice() {
        const {
            isLikeTable,
            currency_code,
            item: {
                qty,
                row_total,
                row_total_incl_tax
            }
        } = this.props;

        return (
            <CartItemPrice
              row_total={ row_total / qty }
              row_total_incl_tax={ row_total_incl_tax / qty }
              currency_code={ currency_code }
              mix={ {
                  block: 'CartItem',
                  elem: 'Price',
                  mods: { isLikeTable }
              } }
            />
        );
    }

    renderDeleteButton() {
        const { handleRemoveItem, isCart, isCheckout } = this.props;

        if (isCheckout) {
            return null;
        }

        return (
            <button
              block="CartItem"
              elem="DeleteButton"
              mods={ { isCart } }
              id="RemoveItem"
              name="RemoveItem"
              aria-label="Remove item from cart"
              onClick={ handleRemoveItem }
            >
                <BsTrash
                  size="18px"
                />
            </button>
        );
    }

    renderCoverage() {
        const {
            item: {
                qty,
                product: {
                    type_id,
                    sku,
                    attributes: {
                        coverage: {
                            attribute_value
                        } = {}
                    },
                    dynamicAttributes: {
                        coverage_area_type,
                        attribute_set_name
                    }
                } = {}
            } = {},
            isCart
        } = this.props;

        if (type_id === CONFIGURABLE
            || type_id === VIRTUAL
            || attribute_set_name === ACCESSORIES_ATTRIBUTE
            || sku.endsWith('-sample')
        ) {
            return null;
        }

        const totalCoverage = new Intl.NumberFormat().format(parseFloat((attribute_value * qty).toFixed(2)));

        return (
            <div block="CartItem" elem="Coverage" mods={ { isCart } }>
                { `Coverage: ${totalCoverage} ${ coverage_area_type }` }
                <span> / </span>
            </div>
        );
    }

    renderCustomAddToCart() {
        const {
            item: {
                product: {
                    id
                } = {}
            },
            maxSaleQuantity,
            onConfirmQtyChange,
            cartItemQuantity,
            isConfirmQtyVisible,
            hideConfirmQty,
            onConfirmClick,
            isAddingAccessory,
            isCustomQtyInputVisible,
            qtyInputRef
        } = this.props;

        return (
            <div
              block="ProductCard"
              elem="QuantityConfirmWrapper"
              mods={ { isCustomQtyInputVisible } }
            >
              <div
                block="ProductCard"
                elem="Tooltip"
                mods={ { isVisible: isConfirmQtyVisible } }
              >
                  <span
                    block="ProductCard"
                    elem="TooltipText"
                  >
                      { __('Click to confirm this amount') }
                  </span>
              </div>
              <div
                block="ProductCard"
                elem="QuantityWrapper"
              >
              <Loader isLoading={ isAddingAccessory } />
              <Field
                id={ `AddToCartQty-custom-${ id }` }
                name="AddToCartQty"
                type="number"
                formRef={ qtyInputRef }
                autoFocus
                min={ 0 }
                max={ maxSaleQuantity }
                mix={ { block: 'ProductCard', elem: 'AddToCartQty', mods: { isConfirm: isConfirmQtyVisible } } }
                value={ cartItemQuantity }
                allowEmptyValue
                onChange={ onConfirmQtyChange }
              />
              <BsX
                block="ProductCard"
                elem="CancelButton"
                mods={ { isVisible: isConfirmQtyVisible } }
                onClick={ hideConfirmQty }
              />
              <BsCheck2
                block="ProductCard"
                elem="ConfirmButton"
                mods={ { isVisible: isConfirmQtyVisible } }
                onClick={ onConfirmClick }
              />
              </div>
            </div>
        );
    }

    renderAccessoryAddToCart() {
        const {
            item: {
                product: {
                    sku,
                    id
                } = {}
            },
            maxSaleQuantity,
            onCartItemAddToCart,
            cartItemQuantity,
            toggleCustomQtyInput,
            isAddingAccessory,
            isCustomQtyInputVisible,
            isCartPopup
        } = this.props;

        const isPremiumCustomer = sku === PREMIUM_CUSTOMER;
        const conditionalMaxSaleQuantity = isPremiumCustomer ? 1 : maxSaleQuantity;
        return (
            <div
              block="ProductCard"
              elem="AccessoryAddToCartWrapper"
              mods={ { isAddToCartVisible: !isCustomQtyInputVisible, isCartPopup } }
            >
                <Loader isLoading={ isAddingAccessory } />
                <Field
                  id={ `AddToCartQty-${ id }` }
                  name="AddToCartQty"
                  type="number"
                  isControlled
                  min={ 0 }
                  max={ conditionalMaxSaleQuantity }
                  mix={ { block: 'ProductCard', elem: 'AddToCartQty', mods: { isPremiumCustomer } } }
                  value={ cartItemQuantity }
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={ (value) => onCartItemAddToCart(value) }
                  onClick={ toggleCustomQtyInput }
                  isLoading={ isAddingAccessory }
                />
            </div>
        );
    }

    renderCustomOrDefaultButton() {
        return (
            <>
                { this.renderCustomAddToCart() }
                { this.renderAccessoryAddToCart() }
            </>
        );
    }

    renderWishlistButton() {
        const {
            addProductToWishlist
        } = this.props;

        return (
            <div block="CartItem" elem="MoveToWishlist">
                <button
                  block="CartItem"
                  elem="Link"
                  onClick={ addProductToWishlist }
                >
                    <span>
                        { __('Add to Wishlist') }
                    </span>
                </button>
            </div>
        );
    }

    renderCartAccessories() {
        const {
            showAccessoriesPopup,
            item: {
                sku,
                item_id,
                product: {
                    dynamicAttributes: {
                        attribute_set_name
                    } = {}
                } = {}
            } = {}
        } = this.props;

        if ((
            attribute_set_name !== FLOORING_ATTRIBUTE
            && attribute_set_name !== RUGS
        ) || sku.includes('-sample')) {
            return null;
        }

        return (
            <button
              block="CartItem"
              elem="AccessoriesButton"
              aria-label="Add accessories to cart"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => showAccessoriesPopup(item_id) }
            >
                <BsPlusSquare size="16px" />
               { __('Add accessories') }
            </button>
        );
    }

    renderAccessoriesPopup() {
        const {
            isCart,
            item: {
                item_id,
                product,
                product: {
                    required_accessories,
                    dynamicAttributes: {
                        attribute_set_name
                    } = {}
                } = {}
            } = {}
        } = this.props;

        if ((attribute_set_name !== FLOORING_ATTRIBUTE && attribute_set_name !== RUGS) || !isCart) {
            return null;
        }

        return (
            <Popup
              id={ ACCESSORIES_POPUP + item_id }
              clickOutside={ false }
              mix={ { block: 'CartItem', elem: 'AccessoriesPopup' } }
            >
                <ProductLinks
                  currentProduct={ product }
                  configurableVariantIndex={ 0 }
                  numberOfProductsToDisplay={ MAX_ACCESSORIES_COUNT }
                  linkType={ CROSS_SELL }
                  title={ __('Accessories you will need') }
                  requiredAccessories={ required_accessories }
                  isCrossSellSection
                  isCartPopup
                />
            </Popup>
        );
    }

    renderWeight() {
        const {
            weight,
            item: {
                product: {
                    dynamicAttributes: {
                        attribute_set_name
                    } = {}
                } = {}
            } = {}
        } = this.props;

        if (attribute_set_name === ACCESSORIES_ATTRIBUTE_SET_NAME) {
            return null;
        }

        const number = parseFloat(weight);
        const formattedWeight = number % 1 !== 0 ? `${ number.toFixed(2) } lb` : `${ parseInt(number, 10) } lb`;

        return (
            <p>
                { __(`Weight: ${ formattedWeight }`) }
            </p>
        );
    }

    renderCartContent() {
        const {
            isCart,
            device,
            isVirtual
        } = this.props;

        if (device.isMobile) {
            return (
                <div block="CartItem" elem="CartContent">
                    <div block="CartItem" elem="PackageProductPrice">
                        { this.renderPackageProductPrice() }
                    </div>
                    <div block="CartItem" elem="QuantityWrapper">
                        { this.renderCustomOrDefaultButton() }
                    </div>
                    <div block="CartItem" elem="ProductPrice">
                        { this.renderProductPrice() }
                    </div>
                    <div block="CartItem" elem="ProductNameWrapper">
                        { this.renderProperties() }
                    </div>
                    <div block="CartItem" elem="ButtonWrapper">
                        <div block="CartItem" elem="DeleteWrapper">
                            { this.renderDeleteButton() }
                        </div>
                    </div>
                    <div block="CartItem" elem="CoverageAndPrice" mods={ { isCart } }>
                        { this.renderCoverage() }
                        { this.renderProductMapPrice() }
                    </div>
                    <div block="CartItem" elem="Weight" mods={ { isCart, isVirtual } }>
                        { this.renderWeight() }
                    </div>
                    <div block="CartItem" elem="Accessories">
                        { this.renderCartAccessories() }
                    </div>
                </div>
            );
        }

        return (
            <div block="CartItem" elem="CartContent">
                <div block="CartItem" elem="PackageProductPrice">
                    { this.renderPackageProductPrice() }
                </div>
                <div block="CartItem" elem="QuantityWrapper">
                    { this.renderCustomOrDefaultButton() }
                </div>
                <div block="CartItem" elem="ProductPrice">
                    { this.renderProductPrice() }
                </div>
                <div block="CartItem" elem="ProductNameWrapper">
                    { this.renderProperties() }
                </div>
                <div block="CartItem" elem="ButtonWrapper">
                    <div block="CartItem" elem="DeleteWrapper">
                        { this.renderDeleteButton() }
                    </div>
                </div>
                <div block="CartItem" elem="CoverageAndPrice" mods={ { isCart } }>
                    { this.renderCoverage() }
                    { this.renderProductMapPrice() }
                </div>
                <div block="CartItem" elem="Weight" mods={ { isCart, isVirtual } }>
                    { this.renderWeight() }
                </div>
                <div block="CartItem" elem="Accessories">
                    { this.renderCartAccessories() }
                </div>
            </div>
        );
    }

    renderAttributes() {
        const {
            isVirtual,
            item: { qty },
            isProductInStock,
            isCheckout = false
        } = this.props;

        if (!isProductInStock) {
            return null;
        }

        return (
            <div block="CartItem" elem="QuantityWrapper">
                <span block="CartItem" elem="QuantityNumber">{ `Qty: ${ qty } ` }</span>
                { this.renderCoverage() }
                { this.renderProductMapPrice() }
                <div block="CartItem" elem="WeightAttribute" mods={ { isCheckout, isVirtual } }>
                    { this.renderWeight() }
                </div>
            </div>
        );
    }

    renderContent() {
        const {
            isLikeTable,
            isCheckout,
            item: {
                bundle_options,
                downloadable_links
            } = {}
        } = this.props;

        return (
                <figcaption
                  block="CartItem"
                  elem="Content"
                  mods={ { isLikeTable, isCheckout } }
                >
                    { this.renderOutOfStockMessage() }
                    <div
                      block="CartItem"
                      elem="DescriptionDeleteWrapper"
                    >
                        { this.renderProperties() }
                        { this.renderDeleteButton() }
                    </div>
                    <div block="CartItem" elem="PriceAndQuantityWrapper" mods={ { isCheckout } }>
                        { this.renderAttributes() }
                        { this.renderProductPrice() }
                    </div>
                    { this.renderProductOptions(bundle_options) }
                    { this.renderProductLinks(downloadable_links) }
                </figcaption>
        );
    }

    render() {
        const { isLoading, isEditing } = this.props;

        return (
            <div block="CartItem" mods={ { isEditing } }>
                <Loader isLoading={ isLoading } />
                { this.renderWrapper() }
                { this.renderAccessoriesPopup() }
            </div>
        );
    }
}

export default CartItemComponent;
