/* eslint-disable max-len */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const MIN_PASSWORD_LENGTH = 8;

export const MIN_PASSWORD_CLASSES = 3;

/** @namespace ZnetPwa/Component/Form/Config/validateEmail */
export const validateEmail = ({ value }) => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

/** @namespace ZnetPwa/Component/Form/Config/validateEmails */
export const validateEmails = ({ value }) => value.split(',').every((email) => validateEmail({ value: email.trim() }));

/** @namespace ZnetPwa/Component/Form/Config/validateTelephone */
export const validateTelephone = ({ value }) => value.length > 0 && value.match(/^(?:[0-9-] ?){6,14}[0-9]$/);

/** @namespace ZnetPwa/Component/Form/Config/isNotEmpty */
export const isNotEmpty = ({ value }) => value.trim().length > 0;

/** @namespace ZnetPwa/Component/Form/Config/isNotFalse */
export const isNotFalse = ({ value }) => value !== 'false';

/** @namespace ZnetPwa/Component/Form/Config/validatePasswordMatch */
export const validatePasswordMatch = ({ value }, { password }) => {
    const { current: { value: passwordValue } } = password || { current: {} };
    return value === passwordValue;
};

/** @namespace ZnetPwa/Component/Form/Config/validatePassword */
export const validatePassword = ({ value }) => value.length >= MIN_PASSWORD_LENGTH;

/** @namespace ZnetPwa/Component/Form/Config/validatePasswordClasses */
export const validatePasswordClasses = ({ value }) => {
    const number = {
        upper: 0,
        lower: 0,
        number: 0,
        special: 0,
        classes: 0,
        i: 0
    };

    for (number.i; number.i < value.length; number.i++) {
        if (value[number.i] >= 'A' && value[number.i] <= 'Z') {
            number.upper++;
        } else if (value[number.i] >= 'a' && value[number.i] <= 'z') {
            number.lower++;
        } else if (value[number.i] >= '0' && value[number.i] <= '9') {
            number.number++;
        } else {
            number.special++;
        }
    }
    if (number.upper > 0) {
        number.classes++;
    }
    if (number.lower > 0) {
        number.classes++;
    }
    if (number.number > 0) {
        number.classes++;
    }
    if (number.special > 0) {
        number.classes++;
    }
    if (number.classes >= MIN_PASSWORD_CLASSES) {
        return true;
    }

    return false;
};

/** @namespace ZnetPwa/Component/Form/Config/formConfig */
export const formConfig = () => ({
    email: {
        validate: validateEmail,
        message: __('Email is invalid.')
    },
    emails: {
        validate: validateEmails,
        message: __('Email addresses are not valid')
    },
    telephone: {
        validate: validateTelephone,
        message: __('Phone number is invalid!')
    },
    notEmpty: {
        validate: isNotEmpty,
        message: __('This field is required!')
    },
    notFalse: {
        validate: isNotFalse,
        message: __('This field is required!')
    },
    password_match: {
        validate: validatePasswordMatch,
        message: __('Password does not match.')
    },
    password: {
        validate: validatePassword,
        message: __('Minimum length of this field must be equal or greater than 8 symbols. Leading and trailing spaces will be ignored.')
    },
    password_classes: {
        validate: validatePasswordClasses,
        message: __('Minimum of different classes of characters in password is 3. Classes of characters: Lower Case, Upper Case, Digits, Special Characters.')
    }
});

export default formConfig();
