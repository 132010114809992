export * from 'SourceStore/MyAccount/MyAccount.action';

export const UPDATE_INSTALLATION_STATUS = 'UPDATE_INSTALLATION_STATUS';
export const UPDATE_INSTALLATION_STATUS_ERROR = 'UPDATE_INSTALLATION_STATUS_ERROR';
export const UPDATE_PICKUP_ACKNOWLEDGEMENT_STATUS = 'UPDATE_PICKUP_ACKNOWLEDGEMENT_STATUS';
export const UPDATE_PICKUP_ACKNOWLEDGEMENT_ERROR = 'UPDATE_PICKUP_ACKNOWLEDGEMENT_ERROR';
export const UPDATE_CURBSIDE_DISCLAIMER_STATUS = 'UPDATE_CURBSIDE_DISCLAIMER_STATUS';
export const UPDATE_CURBSIDE_DISCLAIMER_ERROR = 'UPDATE_CURBSIDE_DISCLAIMER_ERROR';
export const UPDATE_SHIPPING_METHOD = 'UPDATE_SHIPPING_METHOD';

/** @namespace ZnetPwa/Store/MyAccount/Action/updateInstallationStatus */
export const updateInstallationStatus = (status) => ({
    type: UPDATE_INSTALLATION_STATUS,
    payload: status
});

/** @namespace ZnetPwa/Store/MyAccount/Action/updateInstallationStatusError */
export const updateInstallationStatusError = (status, scrollToError) => ({
    type: UPDATE_INSTALLATION_STATUS_ERROR,
    payload: status,
    scrollToError
});

/** @namespace ZnetPwa/Store/MyAccount/Action/updatePickupAcknowledgementStatus */
export const updatePickupAcknowledgementStatus = (status) => ({
    type: UPDATE_PICKUP_ACKNOWLEDGEMENT_STATUS,
    payload: status
});

/** @namespace ZnetPwa/Store/MyAccount/Action/updatePickupAcknowledgementError */
export const updatePickupAcknowledgementError = (status) => ({
    type: UPDATE_PICKUP_ACKNOWLEDGEMENT_ERROR,
    payload: status
});

/** @namespace ZnetPwa/Store/MyAccount/Action/updateCurbsideDisclaimerStatus */
export const updateCurbsideDisclaimerStatus = (status) => ({
    type: UPDATE_CURBSIDE_DISCLAIMER_STATUS,
    payload: status
});
/** @namespace ZnetPwa/Store/MyAccount/Action/updateCurbsideDisclaimerError */
export const updateCurbsideDisclaimerError = (status) => ({
    type: UPDATE_CURBSIDE_DISCLAIMER_ERROR,
    payload: status
});

/** @namespace ZnetPwa/Store/MyAccount/Action/updateShippingMethod */
export const updateShippingMethod = (method) => ({
    type: UPDATE_SHIPPING_METHOD,
    payload: method
});
