import { RADIO_TYPE } from '@scandipwa/scandipwa/src/component/Field/Field.config';
import PropTypes from 'prop-types';

import {
    FieldContainer as SourceFieldContainer
} from 'SourceComponent/Field/Field.container';

import {
    CHECKBOX_TYPE,
    FILE_TYPE,
    NUMBER_TYPE
} from './Field.config';

/** @namespace ZnetPwa/Component/Field/Container/FieldContainer */
export class FieldContainer extends SourceFieldContainer {
    static propTypes = {
        ...this.propTypes,
        additionalDescription: PropTypes.string,
        isSearchSpringFacet: PropTypes.bool,
        count: PropTypes.number,
        allowEmptyValue: PropTypes.bool,
        allowFloat: PropTypes.bool,
        noSortingOrder: PropTypes.bool,
        handleShowPopup: PropTypes.func
    };

    static defaultProps = {
        ...this.defaultProps,
        max: 1000,
        additionalDescription: '',
        isSearchSpringFacet: false,
        count: 0,
        allowEmptyValue: false,
        allowFloat: false,
        noSortingOrder: false,
        handleShowPopup: () => {}
    };

    componentDidUpdate(prevProps) {
        const { value: prevValue, checked: prevChecked } = prevProps;
        const { value: currentValue, checked: currChecked, type } = this.props;

        if (prevValue !== currentValue) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ value: currentValue });
        }
        if (type === CHECKBOX_TYPE && currChecked !== prevChecked) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ checked: currChecked });
        }

        if (type === RADIO_TYPE && currChecked !== prevChecked) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ checked: currChecked });
        }

        this.updateValidationStatus();
        this.setValidationMessage(prevProps);
    }

    containerProps = () => {
        const {
            checked: propsChecked,
            customValidationStatus,
            isSearchSpringFacet,
            count,
            additionalDescription,
            noSortingOrder,
            handleShowPopup
        } = this.props;

        const {
            type,
            checked,
            value,
            validationStatus,
            validationMessage,
            filename
        } = this.state;

        return {
            handleShowPopup,
            additionalDescription,
            isSearchSpringFacet,
            count,
            checked: (type === CHECKBOX_TYPE || type === RADIO_TYPE) ? propsChecked : checked,
            value,
            validationStatus: customValidationStatus ?? validationStatus,
            message: validationMessage,
            filename,
            noSortingOrder
        };
    };

    getInitialPropsValue() {
        const { type, value } = this.props;

        if (value) {
            return value;
        }

        switch (type) {
        case NUMBER_TYPE:
            return 0;
        case CHECKBOX_TYPE:
            return false;
        case RADIO_TYPE:
            return false;
        default:
            return '';
        }
    }

    handleChange(value, shouldUpdate = true, fileValue = false) {
        const {
            isControlled,
            onChange,
            type,
            min,
            max,
            allowEmptyValue,
            allowFloat
        } = this.props;

        switch (type) {
        case NUMBER_TYPE:
            const isValueNaN = Number.isNaN(parseInt(value, 10));
            const isInteger = Number.isInteger(parseFloat(value));

            if (!allowEmptyValue) {
                if (min > value || value > max || isValueNaN) {
                    break;
                }

                if (!allowFloat && !isInteger) {
                    break;
                }
            } else if (value !== '') {
                if (min > value || value > max || isValueNaN) {
                    break;
                }

                if (!allowFloat && !isInteger) {
                    break;
                }
            }

            if (onChange && shouldUpdate) {
                onChange(value);
            }
            if (!isControlled) {
                const numberRegex = /^\d*((\d\.)|(\.\d))?\d{0,2}$/;

                if (numberRegex.test(value)) {
                    this.setState({ value });
                }
            }
            break;
        case FILE_TYPE:
            if (value) {
                const result = onChange && onChange(fileValue);

                this.setState({
                    value: result ? value : '',
                    filename: result ? value.substr(value.lastIndexOf('\\') + 1) : ''
                });
            }

            break;
        default:
            if (onChange) {
                onChange(value);
            }
            if (!isControlled) {
                this.setState({ value });
            }
        }
    }
}

export default FieldContainer;
