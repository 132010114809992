import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { ATATUS_SCRIPT } from './Atatus.config';

/** @namespace Atatus/Route/Atatus/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    atatusEnabled: state.ConfigReducer.atatus_enabled,
    atatusKey: state.ConfigReducer.atatus_key,
    isLoading: state.ConfigReducer.isLoading
});

/** @namespace Atatus/Route/Atatus/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Atatus/Route/Atatus/Container/AtatusContainer */
export class AtatusContainer extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/boolean-prop-naming
        atatusEnabled: PropTypes.bool.isRequired,
        atatusKey: PropTypes.string.isRequired,
        isLoading: PropTypes.bool.isRequired
    };

    state = {
        isAtatusInjected: false
    };

    componentDidMount() {
        this.initAtatus();
    }

    componentDidUpdate(prevProps) {
        const {
            atatusEnabled,
            isLoading
        } = this.props;
        const {
            atatusEnabled: prevAtatusEnabled
        } = prevProps;
        const {
            isAtatusInjected
        } = this.state;

        if (!isLoading && !atatusEnabled !== prevAtatusEnabled && atatusEnabled && !isAtatusInjected) {
            this.initAtatus();
        }
    }

    initAtatus() {
        const existingScripts = document.getElementById(ATATUS_SCRIPT);

        if (!existingScripts) {
            const script = document.createElement('script');

            script.id = ATATUS_SCRIPT;

            script.src = 'https://dmc1acwvwny3.cloudfront.net/atatus-spa.js';

            script.async = true;

            script.onload = () => this.configAtatus();

            document.head.insertBefore(script, document.head.childNodes[0]);
        }
    }

    configAtatus() {
        const {
            atatusEnabled,
            atatusKey
        } = this.props;
        const {
            isAtatusInjected
        } = this.state;

        if (atatusEnabled && !isAtatusInjected) {
            window.atatus.config(atatusKey).install();
            this.setState({ isAtatusInjected: true });
        }
    }

    render() {
        // Without this, atatus script is not added
        // for customer browsing for the very first time
        this.initAtatus();

        return null;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AtatusContainer));
