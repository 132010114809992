import { Field } from '@scandipwa/scandipwa/src/util/Query';

import {
    CheckoutQuery as SourceCheckoutQuery
} from 'SourceQuery/Checkout.query';

/** @namespace ZnetPwa/Query/Checkout/Query/CheckoutQuery */
export class CheckoutQuery extends SourceCheckoutQuery {
    setPickupInformation(addressInformation, pickupInformation) {
        const mutation = new Field('savePickupInformation')
            .addArgument('addressInformation', 'SaveAddressInformation!', addressInformation)
            .addArgument('pickupInformation', 'String', pickupInformation);

        return mutation;
    }

    _getCustomerAddressFields() {
        return [
            'firstname',
            'lastname',
            'email',
            'company',
            'telephone',
            'country_id',
            'street',
            'city',
            'postcode',
            'region',
            'region_id'
        ];
    }

    _getPickupInformationFields() {
        return new Field('pickup_information')
            .addFieldList([
                'standardHours',
                'locationId',
                'city',
                'country',
                'name',
                'phone',
                'postcode',
                'region',
                'street',
                'address'
            ]);
    }

    _getEstimatedShippingFields() {
        return [
            'amount',
            'available',
            'base_amount',
            'method_code',
            'carrier_code',
            'method_title',
            'carrier_title',
            'error_message',
            'price_excl_tax',
            'price_incl_tax',
            this._getPickupInformationFields()
        ];
    }

    _getCustomerAddressField() {
        return new Field('quote_address')
            .addFieldList(this._getCustomerAddressFields());
    }

    _getTotalsFields() {
        return [
            this._getCustomerAddressField(),
            'discount_labels',
            'subtotal',
            'tax_amount',
            'base_grand_total',
            'grand_total',
            'discount_amount',
            'shipping_amount',
            'subtotal_incl_tax',
            'shipping_incl_tax',
            'quote_currency_code',
            'shipping_tax_amount',
            'subtotal_with_discount',
            'shipping_discount_amount',
            this._getTotalItemField()
        ];
    }
}

export default new CheckoutQuery();
