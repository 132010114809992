/*
 * @category  Znet
 * @author    Vladislavs Jerins <info@scandiweb.com>
 */

import {
    CartQuery as SourceCartQuery
} from 'SourceQuery/Cart.query';
import { Field } from 'Util/Query';

/** @namespace ZnetPwa/Query/Cart/Query/CartQuery */
export class CartQuery extends SourceCartQuery {
    _getCustomerAddressFields() {
        return [
            'firstname',
            'lastname',
            'email',
            'company',
            'telephone',
            'country_id',
            'street',
            'city',
            'postcode',
            'region',
            'region_id'
        ];
    }

    _getCustomerAddressField() {
        return new Field('quote_address')
            .addFieldList(this._getCustomerAddressFields());
    }

    _getCartTotalsFields() {
        return [
            this._getCustomerAddressField(),
            'discount_labels',
            'subtotal',
            'subtotal_incl_tax',
            'items_qty',
            'tax_amount',
            'grand_total',
            'discount_amount',
            'quote_currency_code',
            'subtotal_with_discount',
            'coupon_code',
            'shipping_amount',
            'shipping_incl_tax',
            'shipping_tax_amount',
            'is_virtual',
            'applied_rule_ids',
            'shipping_amount',
            'shipping_incl_tax',
            'shipping_tax_amount',
            'shipping_method',
            this._getCartItemsField(),
            this._getAppliedTaxesField()
        ];
    }
}

export default new CartQuery();
