/* eslint-disable max-lines */
/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import GoogleTagManagerComponent from './GoogleTagManager.component';

/** @namespace Gtm/Component/GoogleTagManager/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    gtm: state.ConfigReducer.gtm,
    state,
    isAtatusEnabled: state.ConfigReducer.atatus_enabled,
    atatusKey: state.ConfigReducer.atatus_key
});

/** @namespace Gtm/Component/GoogleTagManager/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    dispatch
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GoogleTagManagerComponent));
